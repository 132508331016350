.about {
    background-image : url('../images/about-bg.jpg');
    background-repeat: no-repeat;
    background-size  : cover;
}

.star {
    width: 50px;
    position: absolute;
    z-index: 0;
}

.about-container {
    display: block;
    background-color: #fff;
    width: 825px;
    margin: 75px auto;
    padding: 20px;
    border-radius: 30px;
    box-shadow: 0px 0px 20px #000;
}

.about-text {
    position: relative;
    z-index: 1;
    font-size: 22px;
    color: #212121;
    margin-left: 55px;
    padding-top: 0;
    margin-bottom: 0;
    margin-top: 23px;
}

.about-subtext {
    font-size: 16px;
    color: #575757;
    margin-left: 25px;
    max-width: 800px;
    line-height: 25px;
}

@media screen and (max-width: 950px) {
    .about-container {
        max-width: 85vw;
    }
}