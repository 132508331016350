html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
}

div {
  font-family: Roboto, sans-serif;
}

@font-face {
  font-family: ArchivoBlack;
  src: url("../fonts/Archivo_Black/ArchivoBlack-Regular.ttf");
}

@font-face {
  font-family: NotoSans;
  src: url("../fonts/Noto_Sans/NotoSans-Regular.ttf");
}

@font-face {
  font-family: Roboto;
  src: url("../fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: Eurostile;
  src: url("../fonts/Eurostile/EurostileRound-Regular.ttf");
}