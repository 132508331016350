.navigation {
    margin-top: 30px;
}

.logo {
    width      : 50px;
    margin-left: 5vw;
    margin-top: 14px;
}

.logoText {
    display       : inline;
    color         : #eb4034;
    font-size     : 18px;
    margin-left   : 10px;
    vertical-align: 18px;
}

.navBar {
    display            : inline;
    position           : absolute;
    right              : 5vw;
    margin-left        : 75px;
    box-shadow         : 0 0 20px #636363;
    border-radius      : 19px;
    padding            : 12px 5px;
    vertical-align     : 18px;
    padding-left       : 22px;
    transition         : all ease-in-out 0.25s;
    transition-property: width, opacity;
    max-height         : 22px;
}

.navItem {
    display       : inline;
    margin-right  : 30px;
    font-size     : 18px;
    transition    : all 0.25s ease-in-out;
    border-bottom : 2px solid transparent;
    padding-bottom: 9px;
}

.navItem:hover {
    border-color: #000;
}

.linkText {
    text-decoration: none;
    color          : #eb4034;
    white-space    : nowrap;
    overflow       : hidden;
    text-overflow: clip;
}

@media screen and (max-width: 1250px) {
    .navBar {
        display : block;
        position: relative;
        right   : 0;
        width   : 713px;
        margin  : 20px auto 0;
    }
}

@media screen and (max-width: 810px) {
    .navBar {
        width  : 0;
        opacity: 0;
    }

    .navItem {
        font-size: 0;
    }

    .linkText {
        opacity: 0;
        pointer-events: none;
    }
}