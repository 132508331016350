.header {
    background-image: url('../images/header-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 1131px;
}

.shader {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 1131px;
    opacity: 15%;
}

.title {
    position: relative;
    z-index: 1;
    font-family: ArchivoBlack;
    color: #fff;
    font-size: 65px;
    text-align: center;
    margin: 0;
    padding-top: 467px;
    text-shadow: -5px -10px 12px #000;
    line-height: 30px;
}

.subtitle {
    position: relative;
    z-index: 1;
    font-family: NotoSans;
    color: #ffc9d8;
    font-size: 50px;
    text-align: center;
    margin: 0;
    text-shadow: 0 0 12px #000;
}

.intro {
    position: relative;
    z-index: 1;
    color: #fff;
    text-align: center;
    margin: 0 auto;
    font-size: 26px;
    line-height: 30px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.contactButton {
    display: block;
    position: relative;
    z-index: 1;
    color: #e0829e;
    background-color: #fff;
    border: none;
    border-radius: 19px;
    font-size: 18px;
    padding: 12px 5px;
    text-decoration: none;
    margin: 30px auto 0;
    width: 170px;
    text-align: center;
    transition: all 0.25s ease-in-out;
}

.contactButton:hover {
    background-color: #e0829e;
    color: #fff;
}

.phone-box {
    display: block;
    position: relative;
    z-index: 1;
    color: #eb4034;
    background-color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    padding: 4px 8px 4px 6px;
    text-decoration: none;
    width: 136px;
    text-align: center;
    margin: 5px 25px 0 auto;
}

.phone-img {
    display: inline-block;
}

.phone-box:hover .phone-img {
    animation-name: ring;
    animation-duration: 0.75s;
    animation-iteration-count: infinite;
}

.header-divider {
    margin-top: 195px;
}

@keyframes ring {
    0% {
        transform: rotate(0) scale(1);
    }

    10% {
        transform: rotate(-20deg) scale(1.2);
    }

    20% {
        transform: rotate(0) scale(1);
    }

    30% {
        transform: rotate(20deg) scale(1.2);
    }

    40% {
        transform: rotate(0) scale(1);
    }

    50% {
        transform: rotate(-20deg) scale(1.2);
    }

    60% {
        transform: rotate(0) scale(1);
    }

    70% {
        transform: rotate(20deg) scale(1.2);
    }

    80% {
        transform: rotate(0) scale(1);
    }

    100% {
        transform: rotate(0) scale(1);
    }
}

@media screen and (max-width: 850px) {
    .title {
        max-width: 99%;
        line-height: 50px;
    }

    .header-divider {
        margin-top: 95px;
    }
}

@media screen and (max-width: 771px) {
    .subtitle {
        line-height: 40px;
        margin-top: 14px;
        margin-bottom: 14px;
    }

    .header-divider {
        margin-top: 55px;
    }
}

@media screen and (max-width: 744px) {
    .header-divider {
        margin-top: 25px;
    }
}