.medicare {
    background-image   : url('../images/medicare-bg.jpg');
    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: 0% 20%;
    height             : 800px;
}

.medicare-container {
    display         : block;
    background-color: #fff;
    width           : 40vw;
    max-width       : 825px;
    min-width       : 350px;
    margin          : 250px 50px;
    padding         : 20px;
    border-radius   : 30px;
    box-shadow      : 0px 0px 20px #000;
}

.medicare-title {
    font-size    : 22px;
    color        : #212121;
    margin-left  : 25px;
    padding-top  : 8px;
    margin-bottom: 0;
    margin-top   : 30px;
}

.medicare-text {
    font-size  : 16px;
    color      : #575757;
    margin-left: 25px;
    max-width  : 800px;
    line-height: 25px;
}

@media screen and (max-width: 510px) {
    .medicare-container {
        margin: 250px auto;
    }
}