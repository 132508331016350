.partsTitle {
    color        : #212121;
    font-size    : 36px;
    margin-bottom: 10px;
    padding      : 0 20px;
}

.partsText {
    max-width      : 800px;
    text-align     : justify;
    justify-content: center;
    font-size      : 16px;
    line-height    : 25px;
    color          : #575757;
    padding        : 0 20px;
}

.partsContainer {
    margin   : 0 auto;
    max-width: 800px;
}

@media screen and (max-width: 720px) {
    .headingText {
        max-width: 430px;
    }
}