.contact {
    background-color: #7ae0ff;
    padding-bottom: 20px;
}

.contact-container {
    display         : block;
    background-color: #fff;
    width           : 70vw;
    max-width: 1170px;
    margin          : 50px auto;
    padding         : 20px;
    border-radius   : 30px;
    box-shadow      : 0px 0px 20px #000;
}

.contactTitle {
    color        : #212121;
    font-size    : 36px;
    text-align: center;
}

.contactText {
    color        : #575757;
    font-size    : 16px;
    text-align: center;
    margin: 0 25px 15px;
}

.phone {
    text-decoration: none;
    color: #e0829e;
}

.contact-info {
    display: flex;
    margin-left: 25px;
    justify-content: space-evenly;
}

.hours {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    padding-right: 10px;
}

.hoursText {
    margin-bottom: 0;
    line-height: 20px;
    color: #575757;
}

.location {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

@media screen and (max-width: 610px) {
    .contact-info {
        flex-direction: column;
    }
}