.headshot {
    margin         : 150px 0;
    display        : flex;
    align-items    : center;
    justify-content: center;
}

.headingText {
    font-family : ArchivoBlack;
    color       : #fff;
    text-shadow : 0 -5px 20px #636363;
    font-size   : 65px;
    line-height : 50px;
    width       : 600px;
    text-align  : center;
    margin-right: 30px;
}

.headshotImg {
    height       : 400px;
    border-radius: 30px;
}

.understand {
    font-family : ArchivoBlack;
    color       : #fff;
    text-shadow : 0 -5px 20px #636363;
    font-size   : 65px;
    line-height : 50px;
    width       : 600px;
    text-align  : center;
    margin-right: 30px;
}

@media screen and (max-width: 1010px) {
    .headshot {
        flex-direction: column;
        margin-top: 100px;
    }
}

@media screen and (max-width: 550px) {
    .understand {
        font-size: 11.5vw;
    }
}